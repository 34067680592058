import React, { useState, useRef } from 'react';
import './App.css';
import VideoRecorder from 'react-video-recorder';

const Overlay = React.memo((props: { readOnly: boolean }) => {
	const { readOnly } = props;
	const [script, setScript] = useState<string>();
	const [scriptBox, showScriptBox] = useState<boolean>(false);
	const textArea = useRef<HTMLTextAreaElement>(null);
	return (
		<>
			{scriptBox ? (
				<div
					style={{
						position: 'absolute',
						backgroundImage:
							'linear-gradient(rgba(0,0,0,0.5), transparent)',
						height: 'calc(100% - 300px)',
						width: '100%',
						zIndex: '10',
						top: 0,
					}}>
					<textarea
						autoFocus
						ref={textArea}
						value={script}
						onChange={(event) => {
							setScript(event.target.value);
						}}
						readOnly={readOnly}
						style={{
							padding: '5%',
							marginTop: '5%',
							width: '80%',
							height: '100%',
							backgroundColor: 'transparent',
							border: 'none',
							fontSize: 30,
							fontWeight: 'bold',
							textAlign: 'center',
							color: '#FFFFFF',
						}}
					/>
					<br />
					<br />
				</div>
			) : null}
			<div style={{ position: 'absolute', bottom: 10, left: 10 }}>
				<button
					type="button"
					onClick={() => {
						scriptBox ? showScriptBox(false) : showScriptBox(true);
					}}
					style={{
						padding: 10,
					}}>
					{scriptBox ? 'HIDE SCRIPT' : 'SHOW SCRIPT'}
				</button>
			</div>
		</>
	);
});

function App() {
	const [readOnly, setReadOnly] = useState<boolean>(false);

	console.log('rerender');

	return (
		<div className="App">
			<header className="App-header">
				<VideoRecorder
					isFlipped={false}
					isOnInitially={true}
					onStartRecording={() => {
						setReadOnly(true);
					}}
					onStopRecording={() => {
						setReadOnly(false);
					}}
				/>

				<Overlay readOnly={readOnly} />
			</header>
		</div>
	);
}

export default App;
